import service from './http';
type OpenType = 1 | 2; //打开方式  1会议内 2单独打开

// 获取当前用户权限
export const fetchPower = ({ openWith, meetId }: { openWith: OpenType, meetId: string}) => {
  return service.request({
    method: 'post',
    url: `/api/v4/panel/detail/role`,
    data: {
      meet_id: meetId,
      open_with: openWith,
    },
  });
};

