import service from './http';

type SaveToDriveParams = {
  id: number;
  meet_id: string;
  folder_name: string;
  folder_id: string;
};

type DriveAPIResult<T = any> = {
  code: number;
  msg: string;
  data: T;
};

type ReadTimeParams = {
  page: number;
  page_size: number;
  sort: string;
};

type UploadAPIResult = {
  code: number;
  msg: string;
  data: string;
};

type WhiteboardAuthResult = {
  code: number;
  msg: string;
  data: {
    token: string;
  };
};

type WhiteboardRoomResult = {
  code: number;
  msg: string;
  data: {
    type: string;
    id: string;
    lastConnectionAt: string;
    createdAt: string;
    metadata: {
      color: string;
    };
    defaultAccesses: [string];
    groupsAccesses: {
      product: [string];
    };
    usersAccesses: {
      alice: [string];
    };
  };

  count: number;
};

/**
 * 创建云盘目录
 */
export const createDirectory = (params: Record<'folder_id' | 'folder_name', string>) => {
  return service.request({
    method: 'PUT',
    url: `/api/v4/directory_list`,
    data: params,
  });
};

/**
 * 创建文档副本
 */
export const createDuplicate = (params: Record<'folder_id' | 'uuid' | 'new_name', string>) => {
  return service.request({
    method: 'POST',
    url: `/api/v4/doc_duplicate`,
    data: params,
  });
};

/**
 * 我的空间文件夹
 */
export const fetchMySpace = (params?: Record<'path', string | undefined>) => {
  return service.request<DriveAPIResult>({
    method: 'GET',
    url: `/api/v4/directory_list/${params?.path || '@all@'}?type=2`,
  });
};

/**
 * 公共空间文件夹
 */
export const fetchPublicSpace = (params?: Record<'path', string | undefined>) => {
  return service.request<DriveAPIResult>({
    method: 'GET',
    url: `/api/v4/directory_public/${params?.path || '@all@'}?type=2`,
  });
};

/**
 * 获取分享空间文件夹
 */
export const fetchShareSpace = (params?: Record<'path', string | undefined>) => {
  return service.request({
    method: 'GET',
    url: `/api/v4/share_list/${params?.path || '@all@'}?type=2`,
  });
};

/**
 * 获取文件夹
 */
export const fetchDriveFolder = (params?: Record<'path', string | undefined>) => {
  return service.request({
    method: 'GET',
    url: `/api/v4/directory_list_folder/${params?.path || '@all@'}`,
  });
};

/**
 * 文件转存到云盘
 */
export const saveToDrive = (params: SaveToDriveParams) => {
  return service.request({
    method: 'POST',
    url: `/api/v4/panel/detail/file/saveDrive`,
    data: params,
  });
};

// Felo Doc 转存到云盘
export const saveToDriveFeloDoc = ({ folder_id, new_name, uuid }: { [key: string]: string }) => {
  return service.request({
    method: 'post',
    url: `/api/v4/doc_duplicate`,
    data: {
      folder_id,
      new_name,
      uuid,
    },
  });
};

/**
 * 最近
 */
export const fetchReadTime = ({ page, page_size, sort }: ReadTimeParams) => {
  return service.request({
    method: 'POST',
    url: `/api/v4/user/read_time`,
    data: { page, page_size, sort },
  });
};

/**
 * 我的空间文件
 */
export const fetchMySpaceFiles = (params?: Record<'path', string | undefined>) => {
  return service.request<DriveAPIResult>({
    method: 'GET',
    url: `/api/v4/directory_list/${params?.path || '@all@'}`,
  });
};

/**
 * 公共空间文件
 */
export const fetchPublicSpaceFiles = (params?: Record<'path', string | undefined>) => {
  return service.request<DriveAPIResult>({
    method: 'GET',
    url: `/api/v4/directory_public/${params?.path || '@all@'}`,
  });
};

/**
 * 分享空间文件
 */
export const fetchShareSpaceFiles = (params?: Record<'path', string | undefined>) => {
  return service.request<DriveAPIResult>({
    method: 'GET',
    url: `/api/v4/share_list/${params?.path || '@all@'}`,
  });
};

/**
 * 收藏列表
 */
export const fetchStarList = ({ page, page_size, sort }: ReadTimeParams) => {
  return service.request<DriveAPIResult>({
    method: 'POST',
    url: `/api/v4/file/list_collect/`,
    data: { page, page_size, sort },
  });
};

/**
 * 云盘文件搜索
 */
export const fetchFileSearch = (params?: Record<'name', string | undefined>) => {
  return service.request<DriveAPIResult>({
    method: 'GET',
    url: `/api/v4/file/search?name=${params?.name || ''}`,
  });
};

/**
 * 加入收藏
 */
export const addStar = (id: string) => {
  return service.request<DriveAPIResult>({
    method: 'POST',
    url: `/api/v4/file/add_collect`,
    data: { rune_has_id: id, type: 1 },
  });
};

/**
 * 取消收藏
 */
export const removeStar = (id: string) => {
  return service.request<DriveAPIResult>({
    method: 'POST',
    url: `/api/v4/file/del_collect`,
    data: { id: Number(id) },
  });
};

/**
 * 云盘ID查询aws文件
 */
export const findAwsById = (file_id: string) => {
  return service.request<DriveAPIResult>({
    method: 'POST',
    url: `/api/v4/panel/driveFileUrl`,
    data: { file_id },
  });
};

/**
 * 上传文件
 */
export const uploadDocument = ({ type = 'file', file, id }: { type?: string; file: any; id?: string }) => {
  return service.request<UploadAPIResult>({
    method: 'POST',
    url: `/api/v4/upload_document?type=${type}&id=${id}`,
    data: { file },
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

/**
 * 删除文件
 */

// upload_document_del
export const uploadDocumentDel = ({ type = 'whiteboard_img', id }: { type?: string; id: string }) => {
  return service.request<UploadAPIResult>({
    method: 'POST',
    url: `/api/v4/upload_document_del`,
    data: { type, id },
  });
};

/**
 * 白板liveblocks创建房间
 */

export const createWhiteboardRoom = ({ id }: { id: string }) => {
  const data = Object.assign(
    {
      id: 'my-room-26e343432bf96',
      defaultAccesses: ['room:write'],
      metadata: {
        color: 'blue',
      },
      usersAccesses: {
        alice: ['room:write'],
      },
      groupsAccesses: {
        product: ['room:write'],
      },
    },
    { id },
  );

  return service.request<WhiteboardRoomResult>({
    method: 'POST',
    url: `/api/v4/whiteboards/room`,
    data,
  });
};

/**
 * 白板liveblocks读取房间
 */

export const getWhiteboardRoom = ({ id }: { id: string }) => {
  return service.request<WhiteboardRoomResult>({
    method: 'GET',
    url: `/api/v4/whiteboards/room/${id}`,
  });
};

/**
 * 白板liveblocks读取auth
 */

export const getWhiteboardAuth = ({ room }: { room: string }) => {
  return service.request<WhiteboardAuthResult>({
    method: 'POST',
    url: `/api/v4/whiteboards/authorize`,
    data: {
      room,
    },
  });
};
