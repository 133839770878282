import { TAB_ITEM } from '@/types';
import * as R from 'ramda';
import { isPreviewable } from '@/components/Preview/config';
import { fetchScreenshot, updatePanelSnapshot } from '@/services';
import { createWhiteboardRoom } from '@/services/drive';
import { TEAM_ID } from '@/lib/constants';

export const nowTime = () => new Date().getTime();

export const isServer = typeof navigator === 'undefined';

export const removeById = (arr: TAB_ITEM[], id: number) => R.reject((v: TAB_ITEM) => R.equals(v.id, id))(arr);

export type WITH_ID_OBJECT = {
  id: number;
  [n: string | number]: any;
};

export type GetWhiteboardLinkProps = {
  meetId: string;
  queryTeamId: string;
  whiteboardId: string;
};

// 两个数组中 id 不同的差集
export const arrDiff = (arr1: WITH_ID_OBJECT[], arr2: WITH_ID_OBJECT[]) => {
  const cmp = (x: WITH_ID_OBJECT, y: WITH_ID_OBJECT) => x.id === y.id;
  return [...R.differenceWith(cmp, arr1, arr2), ...R.differenceWith(cmp, arr2, arr1)];
};

// 合并两个数组中的指定 key 值
export const mergeArrayFromKeys = (arr1: TAB_ITEM[], arr2: TAB_ITEM[], keys: string[]) => {
  let tempArr = [];
  for (let i = 0; i < arr1.length; i++) {
    const item1 = arr1[i];
    const item2 = arr2[i];
    if (item1.id === item2.id) {
      tempArr.push(R.mergeAll([item2, R.pick(keys, item1)]));
    }
  }
  return tempArr;
};

// icons
export const iconBasePath = '/assets/images/';
const iconType = '.svg';
const iconsMap = {
  img: 'imageIcon',
  edit: 'otherIcon',
  pdf: 'pdfIcon',
  video: 'otherIcon',
  audio: 'otherIcon',
  code: 'otherIcon',
  msDoc: 'wordIcon',
  browser: 'browser',
  folder: 'folder',
  word: 'wordIcon',
  ppt: 'pptIcon',
  excel: 'excelIcon',
  dir: 'folderIcon',
  sheet: 'sheetIcon',
  zip: 'zipIcon',
  whiteboard: 'whiteboardIcon',
};

export const getIcon = (item: any) => {
  let iconName = 'otherIcon';
  let type = '';

  if (String(item) === item) {
    type = item;
  } else {
    const fileType = item.type;
    type = fileType === 'sheet' ? 'sheet' : item.fileExtension || item.type;
  }

  if (type === 'cloudDocument') {
    iconName = 'documentIcon';
  } else if (type === 'dir') {
    if (item.shareCount > 0) {
      iconName = 'folderShareIcon';
    } else {
      iconName = 'folderIcon';
    }
  } else if (type === 'sheet') {
    iconName = 'sheetIcon';
  } else if (isPreviewable(type)) {
    const tmpName = iconsMap[isPreviewable(type) as keyof typeof iconsMap];
    if (tmpName) iconName = tmpName;
  }

  if (item === 7 || item === 'whiteboard') {
    iconName = 'whiteboardIcon';
  }

  return iconBasePath + iconName + iconType;
};

export const isEmbedByMeet = () => {
  const url = window.location != window.parent.location ? document.referrer : document.location.href;
  return /.*?meet.*?\.felo\.me/.test(url);
};

// isSafri
export const isSafari = isServer ? false : /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// requestFullscreen
export const requestFullscreenName = isSafari ? 'webkitRequestFullscreen' : 'requestFullscreen';

// isFullScreenElement
export const isFullScreenElement = isServer
  ? null
  : isSafari
  ? document['webkitFullscreenElement']
  : document.fullscreenElement;

// exitFullscreen
export const exitFullscreenName = isSafari ? 'webkitExitFullscreen' : 'exitFullscreen';

// fullscreenchange
export const fullscreenchangeName = isSafari ? 'webkitfullscreenchange' : 'fullscreenchange';

// 全屏切换
export const fullScreenChange = (status: boolean, element?: HTMLElement) => {
  const isIframe = window.self !== window.top;
  const parentWindow = window.parent;

  element = element ? element : document.documentElement;
  if (window.FeloSDK.isFelo) window.felo.onMeetFullScreen(status);

  if (isIframe) {
    parentWindow.postMessage(String(status), '*');
  } else {
    if (status && !isFullScreenElement) {
      element[requestFullscreenName]();
    } else if (isFullScreenElement) {
      document[exitFullscreenName]();
    }
  }
};

/**
 * 模板 Tag 多语言映射
 */
export const templateLang = {
  'zh-CN': 'zh-Hans',
  'zh-HK': 'zh-Hant',
  'zh-TW': 'zh-Hant',
  'en-US': 'en',
  'ja-JP': 'ja',
};

/**
 * 获取过期时间
 */
export const getExpireTime = (time: number) => (time * 1000 + new Date().getTime()).toString();

/**
 * 检测 iframe load
 */
export const checkIframeLoaded = (setLoading: (data: boolean) => void) => {
  const iframes = document.querySelectorAll('iframe.preview-iframe') || [];

  if (iframes && iframes.length > 0) {
    iframes.forEach((v) => {
      const item = v as HTMLIFrameElement;
      const prevSpin = item.previousSibling as HTMLElement;
      const layoutSpin = item.closest('div.main-preview-layout')?.previousSibling as HTMLElement;
      const previewSpin = item.closest('div.main-preview')?.previousSibling as HTMLElement;

      const spinElement =
        prevSpin && prevSpin.classList && prevSpin.classList.contains && prevSpin.classList.contains('preview-spin')
          ? prevSpin
          : previewSpin &&
            previewSpin.classList &&
            previewSpin.classList.contains &&
            previewSpin.classList.contains('preview-spin')
          ? previewSpin
          : layoutSpin &&
            layoutSpin.classList &&
            layoutSpin.classList.contains &&
            layoutSpin.classList.contains('preview-spin')
          ? layoutSpin
          : null;

      const hideSpin = () => {
        setLoading(false);
        if (spinElement && spinElement.classList && spinElement.classList.remove)
          spinElement.classList.remove('ant-spin-spinning');
      };

      item.onload = () => {
        console.log('iframe loaded');
        hideSpin();
      };

      item.onabort = () => {
        console.log('iframe aborted');
        hideSpin();
      };

      item.onerror = () => {
        console.log('iframe error');
        hideSpin();
      };
    });
  }
};

// 通知 meet.felo.me 加载完成
export const iframeLoadedPub = () => {
  const isIframe = window.self !== window.top;
  const parentWindow = window.parent;

  if (isIframe) {
    parentWindow.postMessage('resourceLoaded', '*');
  }
};

// Next Image 图片占位符图片(loading)
export const nextImageLoading = ({ width, height, size }: { width: number; height: number; size?: string }) => {
  return size === 'small'
    ? `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block; shape-rendering: auto;" width="${width}" height="${height}" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<circle cx="50" cy="50" fill="none" stroke="#2a94ff" stroke-width="3" r="15" stroke-dasharray="70.68583470577033 25.561944901923447">
  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
</circle></svg>
  `
    : `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block; shape-rendering: auto;" width="${width}" height="${height}" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <circle cx="50" cy="50" fill="none" stroke="#2a94ff" stroke-width="0.4" r="2" stroke-dasharray="6 2">
    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
  </circle>
</svg>
  `;
};

// toBase64
export const toBase64 = (str: string) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

// 是否 Felo Doc 详情
export const isFeloDoc = (url: string) => /^https:\/\/(doc-dev|doc)\.felo\.me\/doc\/\d+\/[\w-]+/.test(url);

export const addParamsToUrl = (url: string, params: Record<string, string>): string => {
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);

  // 将每个参数添加到 searchParams 对象中
  for (let param in params) {
    searchParams.set(param, params[param]);
  }

  // 使用新的 searchParams 对象更新 URL 对象的 search 属性
  urlObj.search = searchParams.toString();

  return urlObj.toString();
};

// Felo Doc 添加组织 id
export const feloDocUrlAddTeamId = (url: string, teamId: string) => {
  if (isFeloDoc(url)) {
    return addParamsToUrl(url, { team_id: teamId });
  }
  return url;
};

// 获取 tab 截图并存储
export const screenshotFetchUpdate = (newTabInfo: API.NewTabInfo) => {
  const { id, meet_id, file_type, other_url, type, run_uuid } = newTabInfo;

  if (id && !isServer) {
    console.log('newTabInfo', newTabInfo);

    // Felo Doc 用原有截图接口，图片直接取地址，压缩包不截图
    if (
      (type === 1 && isFeloDoc(other_url)) ||
      isFeloDoc(String(run_uuid)) ||
      isPreviewable(file_type) === 'img' ||
      isPreviewable(file_type) === 'zip' ||
      type === 7
    ) {
      return;
    }

    const url =
      type === 3 && run_uuid
        ? run_uuid
        : encodeURIComponent(
            // `${process.env.NEXT_PUBLIC_PANEL_HOST + window.location.pathname}?tabId=${id}&tabFileType=${file_type}`,
            `${window.location.origin + window.location.pathname}?tabId=${id}&tabFileType=${file_type}`,
          );

    fetchScreenshot(String(url)).then((res) => {
      const snapshotInfo = res?.data?.data;
      const { screenshot = '', screenshotUrl = '', previewshotSrc = '', isEmbeddable = 0 } = snapshotInfo;

      const updateData = {
        detailId: id,
        meetId: meet_id,
        imgBase64: screenshot,
        src: screenshotUrl,
        previewshotSrc,
        isEmbeddable,
      };

      if (updateData) {
        updatePanelSnapshot(updateData).then((screenshotRes) => {
          console.log('screenshot', screenshotRes);
        });
      }
    });
  }
};

// 获取 Felo Doc 网址
export const getFeloDocHost = (url: string) => {
  const result = url.match(/^https:\/\/(doc-dev|doc)\.felo\.me\//g) || [];
  return result[0];
};

// 获取 Felo Doc UUID
export const getFeloDocUuid = (url: string) => {
  const result = url.match(/^https:\/\/(doc-dev|doc)\.felo\.me\/doc\/\d+\/[\w-]+/g) || [];
  const docUrl = result[0] || '';
  const uuid = docUrl.split('/').pop();
  return uuid;
};

// 创建白板参数
export const createWhiteboardParams: Tab.NewTab = {
  type: 7,
  url: 'whiteboard',
  cb: (data: any) => {
    if (data.run_uuid) {
      createWhiteboardRoom({ id: data.run_uuid });
    }
  },
};

// 获取 tab title
export const getTabTitle = (item: TAB_ITEM, { text }: { text: string }) => {
  return item.title ? item.title : `${text}-${item.id}`;
};

// 判断是否是 Youtube 视频链接
export const isYoutubeLink = (url: string) => {
  return /https:\/\/www\.youtube\.com\/embed/.test(url);
};

// 通过 youtube player 获取 state
export const getCurrentState = (video: any) => {
  return {
    playing: video.getPlayerState(),
    seconds: video.getCurrentTime(),
    volume: video.getVolume(),
    muted: video.isMuted(),
    rate: video.getPlaybackRate(),
    quality: video.getPlaybackQuality(),
  };
};

/**
 * tldraw多语言映射
 */
export const tldrawLang = {
  'zh-CN': 'zh-tw',
  'zh-HK': 'zh-tw',
  'zh-TW': 'zh-tw',
  'en-US': 'en',
  'ja-JP': 'ja',
};

/**
 * 登录 url
 */
export const loginRedirectUrl = !isServer
  ? /panel\.felo/.test(window.location.host)
    ? 'https://account.felo.me/signin?redirect_url='
    : 'https://api.sandbox.felo.me/signin?redirect_url='
  : '';

/**
 * 面板 bar 高度
 */
export const barHeight = 32;
/**
 * 演示 bar 高度
 */
export const demoBarHeight = 38;

/**
 * 面板 publicApiKey
 */
export const whiteboardPublicKey = !isServer
  ? /panel\.felo/.test(window.location.host)
    ? 'pk_prod_XnBhHzuDV_mTUm8RVn-c2p2fKpV-oMsOQX5V1IlBvk0FYmGQrxg7AcTCLHyvGBYG'
    : 'pk_dev_J8zygmDq2knQMuYJ7BXErLRAkKG0NC2YyF-UnqO-Edh3ZTigPchotHoTRIDBjeZO'
  : '';

export const chromeVersion = () => {
  const result = isServer ? [] : /chrome\/(\d+)/.exec(window.navigator.userAgent.toLocaleLowerCase());

  return result && result.length > 1 ? Number(result[1] || 0) : 0;
};

/**
 * 获取剪切板内容
 */
export const getClipboardPaste = (e: ClipboardEvent, fn: (text: string) => void) => {
  e.preventDefault();
  const clipboardData = e.clipboardData || window.clipboardData;
  const text = clipboardData.getData('text');

  fn && fn(text);
};

/**
 * isNumber
 */
export const isNumber = (input: any) => {
  return typeof input === 'number' || Object.prototype.toString.call(input) === '[object Number]';
};

/** ---------------- 未登录用户，记录文档密码验证结果，以免死循环到输入密码位置 ---------------------- */
export const setDocVerify = (id: string, verify: string) => {
  !isServer && window.sessionStorage.setItem(`roomId_${id}`, verify);
};

export const getDocVerify = (id: string) => {
  return !isServer && window.sessionStorage.getItem(`roomId_${id}`);
};
/** ---------------- end ---------------- */

/**
 * teamId
 */
export const getTeamId = !isServer && window.sessionStorage.getItem(TEAM_ID);

/**
 * isLogin
 */

export const isLogin = getTeamId && getTeamId !== 'anonymous' ? true : false;

/**
 * 浏览器滚动条宽度
 */
export const getScrollBarSize = () => {
  if (isServer) return 0;
  let cached = 0;

  const inner = document.createElement('div');
  inner.style.width = '100%';
  inner.style.height = '200px';

  const outer = document.createElement('div');
  const outerStyle = outer.style;

  outerStyle.position = 'absolute';
  outerStyle.top = '0';
  outerStyle.left = '0';
  outerStyle.pointerEvents = 'none';
  outerStyle.visibility = 'hidden';
  outerStyle.width = '200px';
  outerStyle.height = '150px';
  outerStyle.overflow = 'hidden';

  outer.appendChild(inner);

  document.body.appendChild(outer);

  const widthContained = inner.offsetWidth;
  outer.style.overflow = 'scroll';
  let widthScroll = inner.offsetWidth;

  if (widthContained === widthScroll) {
    widthScroll = outer.clientWidth;
  }

  document.body.removeChild(outer);

  cached = widthContained - widthScroll;

  return cached;
};

/**
 * 支持标记类型
 */
export const markerSupportList = ['pdf', 'img'];

/**
 * 获取白板链接
 */
export const getWhiteboardLink = (params: GetWhiteboardLinkProps) => {
  const { meetId, queryTeamId, whiteboardId } = params;
  const link = !isServer ? `${window.location.origin}/meet/${meetId}/${queryTeamId}/${whiteboardId}` : '';
  return link;
};

/**
 * 通过文件名获取文件类型
 */
export const getFileTypeFromName = (filename: string) => {
  const match = filename.match(/.*\.(.*)/);
  if (match) {
    return match[1];
  }
  return filename;
};
