import { PropsWithChildren, useState, useCallback, useContext, useEffect, useRef } from 'react';
import { ResourceType, TabContext } from './Context';
import { MQTTContext } from '../MQTTProvider/Context';
import { AuthContext } from '../AuthProvider/Context';
import { useRouter } from 'next/router';
import {
  createPanel,
  deleteBatchPanel,
  deletePanel,
  fetchPanelList,
  setDocShareType,
  updateActiveTab,
  fetchActiveTab,
  fetchTabTitle,
} from '@/services';
import * as R from 'ramda';
import { useIntl } from 'react-intl';
import PubSub from 'pubsub-js';
import { arrDiff, WITH_ID_OBJECT, screenshotFetchUpdate, mergeArrayFromKeys } from '@/utils';
import { TAB_ITEM, MARKER_ITEM } from '@/types';
import { message } from 'antd';

const CACHE_TABS_LIMIT = 3;

export function TabProvider({ children }: PropsWithChildren<unknown>) {
  const [activeTabId, originSetActiveTabId] = useState<number>(0); // 当前选中 tab id
  const [orderItems, setOrderItems] = useState<TAB_ITEM[]>([]); // 排序的 tabs
  const [items, setItems] = useState<WITH_ID_OBJECT[]>([]); // tabs 数组
  const [tabsLoading, setTabsLoading] = useState(false); // tabs loading 状态
  const [resourceType, setResourceType] = useState<ResourceType>(); // 资源类型
  const [docAddLoading, setDocAddLoading] = useState(''); // 添加 doc 文档 loading 状态
  const [refreshTabId, setRefreshTabId] = useState(''); // 需要刷新的 tabId
  const [youtubePlayers, setYoutubePlayers] = useState({}); // youtube 播放实例
  const [addedFiles, setAddedFiles] = useState<any[]>([]); // 记录已添加文件地址 url
  const cacheTabs = useRef<number[]>([]); // tabs 缓存 id 数组
  const { client, baseConfig } = useContext(MQTTContext);
  const { state, canEdit, isDemoHost, isDemoMode, updatePower } = useContext(AuthContext);
  const [showSnapshoot, setShowSnapshoot] = useState(false);
  const [markerVisible, setMarkerVisible] = useState<MARKER_ITEM>({});
  const [isShowDemoTip, setIsShowDemoTip] = useState(false);

  const intl = useIntl();
  const router = useRouter();
  const meetId = (router.query.meetId || '') as string;

  // addCache 添加 id 方法
  const addCache = (id: number) => {
    if (!cacheTabs.current.includes(id) && cacheTabs.current.length < CACHE_TABS_LIMIT) {
      cacheTabs.current.push(id);
    } else {
      const index = cacheTabs.current.indexOf(id);
      if (index === -1) {
        cacheTabs.current = [...cacheTabs.current.slice(1), id];
      } else {
        cacheTabs.current.splice(index, 1);
        cacheTabs.current.push(id);
      }
    }
  };

  // 重写 setActiveTabId 方法，让activeId 更新的时候 cacheTabs 也更新
  const setActiveTabId = (id: number) => {
    if (id) {
      addCache(id);
    }
    originSetActiveTabId(id);
  };

  const formatData = useCallback(
    (data: TAB_ITEM[]) => {
      if (items.length) {
        let newItems = [...items];
        // 获取不同的 tabs
        const diffArr = arrDiff(newItems, data);

        if (data.length) {
          // tab 新增
          if (newItems.length < data.length) {
            newItems = [...newItems, ...diffArr];
          } else {
            // tab 减少
            const diffArrIds = diffArr.map((v) => v.id);
            newItems = newItems.filter((v) => !diffArrIds.includes(v.id));
          }
          // 更新最新数据
          newItems = newItems.map((v) => {
            const item = data.find((vv) => v.id === vv.id);
            return item ? Object.assign(v, item) : v;
          });
        } else {
          newItems = data;
        }

        setItems(newItems);
      } else {
        setItems(data);
      }

      const newOrderItems = data.sort((a: any, b: any) => a.next - b.next);
      setOrderItems(newOrderItems);
    },
    [items, setItems, setOrderItems],
  );

  const fetchTabs = useCallback(
    (cb?: Function) => {
      if (!meetId) {
        console.log('No meetId.');
        return;
      }
      fetchPanelList({ meetId })
        .then((res) => {
          const { data } = res;
          const { panel_details = [] } = data.data;
          formatData(panel_details);
          fetchTabTitle({ meetId }).then((res) => {
            if (res.data.data) {
              const mergeData = mergeArrayFromKeys(res.data.data, panel_details, [
                'preview_shot_src',
                'snapshot_src',
                'icon',
                'title',
              ]);
              formatData(mergeData as TAB_ITEM[]);
            }
          });
          if (cb && typeof cb === 'function') {
            cb(res);
          }
        })
        .finally(() => setTabsLoading(false));
    },
    [setTabsLoading, meetId, formatData],
  );

  // MQTT 同步更新 activeTabId
  const pubUpdateActiveId = useCallback(
    (id) => {
      // 只有主持人的操作才会同步
      if (client && isDemoHost) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'update_activeTab',
            client_id: baseConfig.client_id,
            payload: {
              activeTabId: id,
            },
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('Send update_activeTab');
            }
          },
        );

        updateActiveTab(id, { meetId })
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => console.log('updateActiveTab error: ', err));
      }
    },
    [client, baseConfig, isDemoHost, meetId],
  );

  useEffect(() => {
    if (isShowDemoTip) {
      return;
    }

    if (isDemoHost) {
      // 成为演示者，主动同步一次 activeTabId
      setTimeout(() => pubUpdateActiveId(activeTabId), 0);
      message.success({
        content: intl.formatMessage({ id: 'tab.top.demo.title.own' }),
        style: {
          marginTop: '20vh',
        },
      });
    } else if (isDemoMode) {
      message.success({
        content: intl.formatMessage({ id: 'tab.top.demo.title' }, { userName: state.role_list.synchro_user_name }),
        style: {
          marginTop: '20vh',
        },
      });
    }

    setIsShowDemoTip(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, isDemoHost, isDemoMode, pubUpdateActiveId]);

  useEffect(() => {
    let demoTipTimeout: NodeJS.Timeout | null = null;

    if (!isShowDemoTip) {
      return;
    }

    demoTipTimeout = setTimeout(() => {
      setIsShowDemoTip(false);
    }, 10 * 1000);

    return () => {
      if (demoTipTimeout) {
        clearTimeout(demoTipTimeout);
      }
    };
  }, [isShowDemoTip]);

  // MQTT 同步更新 tab 排序
  const pubUpdateTabsort = useCallback(
    (newItems) => {
      if (client && isDemoHost) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'reorder',
            client_id: baseConfig.client_id,
            payload: {
              orderItems: newItems,
            },
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('Send reorder');
            }
          },
        );
      }
    },
    [client, baseConfig, isDemoHost],
  );

  // MQTT 同步更新全部 tab
  const mqttPubUpdate = useCallback(() => {
    if (client) {
      client.publish(
        baseConfig.topic,
        JSON.stringify({
          id: 1,
          type: 'update_all',
          client_id: baseConfig.client_id,
        }),
        { qos: 1, retain: false },
        function (error: string) {
          if (error) {
            console.log(error);
          } else {
            console.log('update items');
          }
        },
      );
    }
  }, [client, baseConfig]);

  // MQTT 同步更新状态，如果成为演示者，需要把所有参与者都开启同步状态
  const mqttSyncStatus = useCallback(
    (status) => {
      if (client && isDemoHost) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'sync_status',
            client_id: baseConfig.client_id,
            payload: {
              syncStatus: status,
            },
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('mqttSyncStatus');
            }
          },
        );
      }
    },
    [client, baseConfig, isDemoHost],
  );

  // MQTT 更新 PPT 位置
  const mqttPubUpdatePptIndex = useCallback(
    (pptIndex, tabId) => {
      if (client && isDemoHost) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'sync_ppt',
            client_id: baseConfig.client_id,
            payload: {
              syncPptStatus: {
                pptIndex,
                tabId,
              },
            },
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('mqttPubUpdatePptIndex');
            }
          },
        );
      }
    },
    [client, baseConfig, isDemoHost],
  );

  // MQTT 同步更新 pdf 位置
  const mqttPubUpdatePDF = useCallback(
    (payload) => {
      console.log('mqttPubUpdatePDF');

      if (client && isDemoHost) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'sync_pdf',
            client_id: baseConfig.client_id,
            payload,
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('update items');
            }
          },
        );
      }
    },
    [client, baseConfig, isDemoHost],
  );

  // MQTT 主动获取当前演示者播放进度
  const mqttPubGetYTProgress = useCallback(
    (payload) => {
      console.log('mqttPubGetYTProgress', client, isDemoMode);

      if (client && isDemoMode) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'get_youtube_progress',
            client_id: baseConfig.client_id,
            payload,
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('update items');
            }
          },
        );
      }
    },
    [client, baseConfig, isDemoMode],
  );

  // MQTT 同步更新 youtube 播放状态
  const mqttPubUpdateYT = useCallback(
    (payload) => {
      console.log('mqttPubUpdateYT', client, isDemoHost);

      if (client && isDemoHost) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'sync_youtube',
            client_id: baseConfig.client_id,
            payload,
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('update items');
            }
          },
        );
      }
    },
    [client, baseConfig, isDemoHost],
  );

  const newTab = useCallback(
    async ({ type, url, cb, fromTemplate, docId }: Tab.NewTab) => {
      if (!canEdit) {
        message.warn(intl.formatMessage({ id: 'noRightsEdit' }));
        return;
      }
      setDocAddLoading(url);
      const res = await createPanel({ type, payload: url, meetId, docId });

      if (res.data.code === 403) {
        message.warn(intl.formatMessage({ id: 'noRightsEdit' }));
      } else if (res.data.code === 50010) {
        message.warn(intl.formatMessage({ id: 'tabs.create.limit' }, { limit: res.data.data.max_count }));
      } else {
        if (fromTemplate) {
          setTimeout(async () => {
            // 如果是通过模板创建，则需修改一次文档分享权限
            const response = await setDocShareType(String(res.data.data.run_uuid));
            if (response.data.code !== 0) {
              console.log('setDocShareType request error', res.data);
            }
          }, 0);
        }

        setAddedFiles((prevState) => {
          return [...prevState, url];
        });

        let fetchCallback = () => {};
        // activeTabId 自动切换到最新创建的 tab 上
        const newTabId = R.pathOr('', ['data', 'data', 'id'], res);
        if (newTabId) {
          fetchCallback = () => {
            setActiveTabId(newTabId);
            if (isDemoHost) setTimeout(() => pubUpdateActiveId(newTabId), 0);
          };
        }

        setTimeout(() => {
          screenshotFetchUpdate(res?.data?.data);
          mqttPubUpdate();
          fetchTabs(fetchCallback);
        }, 0);
      }
      setResourceType(undefined);
      setDocAddLoading('');
      cb && cb(res?.data?.data);
    },
    [canEdit, fetchTabs, intl, isDemoHost, meetId, mqttPubUpdate, pubUpdateActiveId],
  );

  const deleteTab = (tabId: number, deleteAllOther = false) => {
    let deleteFn: Function = deletePanel;
    let params: number | number[] = tabId;
    let delRunUuids: any[] = [];

    if (deleteAllOther) {
      // 批量删除
      deleteFn = deleteBatchPanel;
      params = orderItems.map((v) => v.id).filter((id) => id !== tabId);

      delRunUuids = orderItems.filter((v) => v.id !== tabId).map((v) => v.run_uuid);
    } else {
      delRunUuids = orderItems.filter((v) => v.id === tabId).map((v) => v.run_uuid);
    }

    deleteFn(params, { meetId })
      .then(({ data }: { data: { code: number } }) => {
        if (data.code === 403) {
          message.warn(intl.formatMessage({ id: 'noRightsDelete' }));
        } else {
          fetchTabs();
          let newActiveTabId = activeTabId;
          if (deleteAllOther) {
            // 如果批量删除其他，则选中当前 tabId
            newActiveTabId = tabId;
          } else if (tabId === activeTabId) {
            // 如果删除的是当前选中的 tab ，则计算自动切换的新 tab
            if (orderItems.length > 1) {
              const idx = orderItems.findIndex((v: TAB_ITEM) => v.id === tabId);
              if (idx === 0) {
                // 如果删除的是第一个 tab ， 则设置第二个为 active
                newActiveTabId = orderItems[1].id;
              } else {
                // 否则设置前一个 tab 为 active
                newActiveTabId = orderItems[idx - 1].id;
              }
            } else {
              // 设置首页 active
              newActiveTabId = 0;
            }
          }

          // 删除已添加记录
          setAddedFiles((prevState) => {
            const ids = prevState.filter((v) => !delRunUuids.includes(v));
            return [...ids];
          });

          setActiveTabId(newActiveTabId);
          setTimeout(() => {
            mqttPubUpdate();
            pubUpdateActiveId(newActiveTabId);
          }, 0);
        }
      })
      .catch((err: Error) => {
        console.log(err.message);
      });
  };

  // 同步 activeTabId 状态
  const syncActiveTab = () => {
    fetchTabs((response: any) => {
      fetchActiveTab({ meetId: meetId as string }).then((res) => {
        const { active_tab_id = 0 } = res.data.data || {};
        const data: Array<{ id: number }> = R.pathOr([], ['data', 'data', 'panel_details'], response);
        if (active_tab_id === 0) {
          // 如果没有设置 activeId 且协作面板 tabs 不为空，则自动选择第一个 tab
          if (data.length) {
            setActiveTabId(data[0].id);
          } else {
            setActiveTabId(0);
          }
        } else {
          const tabExistItem = data.find((v) => v.id === active_tab_id);
          if (tabExistItem) {
            setActiveTabId(active_tab_id);
          } else {
            setActiveTabId(0);
            updateActiveTab(0, { meetId });
          }
        }
      });
    });
  };

  // MQTT 更新 Felo Doc 位置
  const mqttPubFeloDocPosition = useCallback(
    (payload) => {
      if (client && isDemoHost) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'sync_felo_doc',
            client_id: baseConfig.client_id,
            payload,
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('sync_felo_doc success');
            }
          },
        );
      }
    },
    [client, baseConfig, isDemoHost],
  );

  // MQTT 更新白板画布
  const mqttPubWhiteboard = useCallback(
    (x, y, zoom, width) => {
      if (client) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'sync_whiteboard',
            client_id: baseConfig.client_id,
            payload: {
              x,
              y,
              zoom,
              width,
            },
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('sync_whiteboard');
            }
          },
        );
      }
    },
    [client, baseConfig],
  );

  // MQTT 更新标记
  const mqttPubMarker = useCallback(
    (tabId, x, y, zoom, width, height) => {
      if (client) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'sync_marker',
            client_id: baseConfig.client_id,
            payload: {
              tabId,
              x,
              y,
              zoom,
              width,
              height,
            },
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('sync_marker');
            }
          },
        );
      }
    },
    [client, baseConfig],
  );

  // MQTT 更新标记显示状态
  const mqttPubMarkerVisible = useCallback(
    (payload) => {
      if (client) {
        client.publish(
          baseConfig.topic,
          JSON.stringify({
            id: 1,
            type: 'sync_marker_visible',
            client_id: baseConfig.client_id,
            payload,
          }),
          { qos: 1, retain: false },
          function (error: string) {
            if (error) {
              console.log(error);
            } else {
              console.log('sync_marker_visible');
            }
          },
        );
      }
    },
    [client, baseConfig],
  );

  useEffect(() => {
    // 如果当前选中的 tab 已经被删除，则也同步
    const activeTabIdExist = orderItems.findIndex((v: TAB_ITEM) => v.id === activeTabId);
    let timer: null | ReturnType<typeof setTimeout> = null;
    if (activeTabIdExist === -1) {
      timer = setTimeout(() => {
        setActiveTabId(0);
        updateActiveTab(0, { meetId })
          .then((res) => {})
          .catch((err) => console.log('updateActiveTab error: ', err));
      }, 500);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [orderItems, activeTabId, setActiveTabId, meetId]);

  // 计算如果删除的 tab 存在于 cacheTabs 则更新该值
  useEffect(() => {
    let newCache: number[] = [];
    if (items.length < CACHE_TABS_LIMIT || cacheTabs.current.length === CACHE_TABS_LIMIT) {
      for (const item of items) {
        for (const id of cacheTabs.current) {
          if (id === item.id) newCache.push(id);
        }
        if (newCache.length === CACHE_TABS_LIMIT) break;
      }
      if (!R.equals(cacheTabs.current, newCache)) {
        cacheTabs.current = newCache;
      }
    }
  }, [items]);

  useEffect(() => {
    const mqttSub = PubSub.subscribe('MQTT_CONNECTED', () => {
      updatePower();
      syncActiveTab();
    });
    return () => {
      PubSub.unsubscribe(mqttSub);
    };
  }, []);

  return (
    <TabContext.Provider
      value={{
        fetchTabs,
        activeTabId,
        setActiveTabId,
        items,
        setItems,
        orderItems,
        setOrderItems,
        tabsLoading,
        setTabsLoading,
        setResourceType,
        resourceType,
        docAddLoading,
        setDocAddLoading,
        newTab,
        deleteTab,
        mqttPubUpdate,
        pubUpdateActiveId,
        pubUpdateTabsort,
        refreshTabId,
        setRefreshTabId,
        mqttSyncStatus,
        addedFiles,
        setAddedFiles,
        syncActiveTab,
        mqttPubUpdatePptIndex,
        mqttPubFeloDocPosition,
        showSnapshoot,
        setShowSnapshoot,
        mqttPubUpdatePDF,
        mqttPubWhiteboard,
        mqttPubUpdateYT,
        youtubePlayers,
        setYoutubePlayers,
        mqttPubGetYTProgress,
        mqttPubMarker,
        mqttPubMarkerVisible,
        markerVisible,
        setMarkerVisible,
        cacheTabs: cacheTabs.current,
      }}
    >
      {children}
    </TabContext.Provider>
  );
}
