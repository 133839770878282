import { useEffect } from 'react';
import { iframeLoadedPub, isServer } from '@/utils';
import AppIntl from '@/components/i18n';
import { message } from 'antd';
import Script from 'next/script';
import AppLayout from '@/components/layout/appLayout';
import { AuthProvider } from '@/model/AuthProvider/Provider';
import { MQTTProvider } from '@/model/MQTTProvider/Provider';
import { TabProvider } from '@/model/TabProvider/Provider';
import { AppProps } from 'next/app';
import * as isMobileJs from 'ismobilejs';
import Head from 'next/head';

require('@/assets/antdStyle.less');

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    message.config({
      top: 40,
    });
    // 只有和 iframe 设置同一个 domain 才能通信
    document.domain = 'felo.me';
    iframeLoadedPub();

    const isMobile = isMobileJs.default().phone;
    function setupWebViewJavascriptBridge(callback: (...args: any) => void) {
      if (!isMobile || isServer) return;
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      const WVJBIframe = document.createElement('iframe');
      WVJBIframe.style.display = 'none';
      WVJBIframe.src = 'https://__bridge_loaded__';
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(function () {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    }

    if (!isServer) {
      window.webViewWillCollaBoardClose = () => {};
      setupWebViewJavascriptBridge(function (bridge: any) {
        if (!bridge) return;
        window.bridge = bridge;
      });
      window.webViewWillCollaBoardClose = () => {
        if (window.felo?.onCollaBoardClose) window.felo.onCollaBoardClose();
        else if (window.bridge) window.bridge.callHandler('onCollaBoardClose');
      };
    }
  }, []);

  return (
    <>
      <Head>
        <title>Felo Panel</title>
      </Head>

      <AppIntl>
        <AuthProvider>
          <MQTTProvider>
            <TabProvider>
              <AppLayout>
                <Component {...pageProps} />
              </AppLayout>
            </TabProvider>
          </MQTTProvider>
        </AuthProvider>
      </AppIntl>

      {/* @ts-ignore */}
      {/* {!process.env.IS_PROD_ENV && (
        <Script
          src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
          onLoad={() => {
            const vConsole = new window.VConsole();
          }}
        />
      )} */}
    </>
  );
};

export default App;
