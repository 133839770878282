import { APP_ID, TOKEN_KEY, TEAM_ID, TOKEN_TIME_KEY, OPEN_ID } from '@/lib/constants';
import { useRouter } from 'next/router';
import { fetchPower } from '@/services/auth';
import { IntlContext, localesMap } from '@/components/i18n';
import { isEmbedByMeet, getExpireTime } from '@/utils';
import { OAuthTokenData } from '@/types';
import * as isMobileJs from 'ismobilejs';
import { Spin } from 'antd';
import { PropsWithChildren, useEffect, useReducer, useState, useContext, useCallback } from 'react';
import { Action, ActionKind, AuthContext, AuthState, initialState } from './Context';
import * as R from 'ramda';
import { isArray } from 'lodash';

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case ActionKind.UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const getInitialState = () => {
  return initialState;
};

export const sdkLogin = async (teamId: string[] = [], cb?: Function) => {
  // console.log('sdkLogin', teamId);
  const teamList: OAuthTokenData[] = await window.FeloSDK.login({
    // 设置为 true 防止没有 cookie 自动跳转到 account.felo.me
    anonymously: true,
  });

  // 已登录组织列表为空则匿名登录
  if (!teamList.length) {
    const tmpData = await window.FeloSDK.loginAnonymous();
    // 设置新的 token
    sessionStorage.setItem(TOKEN_KEY, tmpData.access_token);
    sessionStorage.setItem(TEAM_ID, 'anonymous');
    sessionStorage.setItem(TOKEN_TIME_KEY, getExpireTime(tmpData.expires_in));
    return tmpData.access_token;
  } else {
    localStorage.setItem('teams', JSON.stringify(teamList));

    // 如果没有设置 team_id 则默认使用已登录组织列表中第一个组织
    let currentTeam = teamList[0];
    if (teamId[0]) {
      const match = teamList.find((v) => String(v.team.team_id) === teamId[0]);
      if (match) currentTeam = match;
    }

    // 获取个人中心设置的语言项
    const lang = R.pathOr('', ['account_info', 'profile', 'lang'], currentTeam);
    if (cb && lang) {
      cb(localesMap[lang] || 'en-US');
    }

    // 设置新的 token
    sessionStorage.setItem(TOKEN_KEY, currentTeam.login_info.access_token);
    sessionStorage.setItem(TEAM_ID, JSON.stringify(currentTeam.team.team_id));
    sessionStorage.setItem(OPEN_ID, currentTeam.account_info.id);
    sessionStorage.setItem(TOKEN_TIME_KEY, getExpireTime(currentTeam.login_info.expires_in));
    return currentTeam.login_info.access_token;
  }
};

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState, getInitialState);
  const router = useRouter();
  const [meetId, setMeetId] = useState('');
  const [teamId, setTeamId] = useState('');
  const [isEmbedMeeting, setIsEmbedMeeting] = useState(false);
  const { setCurrentLocale } = useContext(IntlContext);
  const isMobile = isMobileJs.default().any;

  // 用户权限
  const { role_list } = state;
  // Dev only 本地开发就不要检测会议模式
  // const isDemoMode = !!role_list.synchro_user; // 是否是演示模式
  // const isDemoHost = role_list.synchro === 1; // 是否是演示人

  // 只有嵌入会议中时有演示模式
  const isDemoMode = isEmbedMeeting && !!role_list.synchro_user; // 是否是演示模式
  const isDemoHost = isEmbedMeeting && role_list.synchro === 1; // 是否是演示人
  const canEdit = role_list.edit === 1; // 是否有编辑权限
  const canDelete = role_list.del === 1 || role_list.del === 3; // 是否有删除权限
  const canUpload = role_list.upload === 1; // 是否有上传权限

  useEffect(() => {
    if (router.isReady) {
      const { meetType } = router.query;
      if (meetType === '1') {
        setIsEmbedMeeting(true);
      } else {
        setIsEmbedMeeting(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  // 更新用户权限
  const updatePower = useCallback(
    (cb?: Function) => {
      // console.log('updatePower');
      if (meetId) {
        // openWith 为 2 是单独使用协作面板的情况，目前咱不存在
        fetchPower({ meetId, openWith: 1 })
          .then((res) => {
            const userPower = R.pathOr(null, ['data', 'data'], res);
            // console.log('stateDiff: ', R.equals(state, userPower), userPower && meetId && !R.equals(state, userPower));

            // 只有当权限发生了变更才触发 hooks
            if (userPower && meetId && !R.equals(state, userPower)) {
              // console.log('dispatch');
              dispatch({ type: ActionKind.UPDATE_STATE, payload: userPower });
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            cb && cb();
            setLoading(false);
          });
      }
    },
    [meetId, state],
  );

  const userLogin = useCallback(
    async (str?: string) => {
      let { teamId = [] } = router.query;
      // console.log('userLogin', router.query);
      teamId = (isArray(teamId) ? teamId : [teamId]) as string[];

      setTeamId(teamId[0]);
      await sdkLogin(teamId as string[], setCurrentLocale);

      updatePower(() => {});
    },
    // 不能把 router 整体当作依赖加入 deps , 不然会 N 次触发
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [meetId, updatePower, router.isReady, setCurrentLocale, setTeamId],
  );

  useEffect(() => {
    if (router.isReady) {
      window.FeloSDK.config({ appId: APP_ID, mode: process.env.IS_PROD_ENV ? 'pro' : 'dev' });

      const { meetId: queryMeetId = '' } = router.query;
      setMeetId(queryMeetId as string);
      if (queryMeetId) localStorage.setItem('meetId', String(queryMeetId));

      userLogin();
    }
    // 这里不能依赖整个 router 不然会N次渲染，只依赖 router.isReady
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogin, updatePower, router.isReady]);

  useEffect(() => {
    if (!loading) {
      // 当组织切换或者个人中心设置变更时重新获取登录信息
      window.FeloSDK.onTeamChange(() => userLogin('onTeamChange'));
    }
  }, [loading, userLogin]);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        meetId,
        teamId,
        canEdit,
        canDelete,
        canUpload,
        setMeetId,
        updatePower,
        isDemoHost,
        userLogin,
        isDemoMode,
        isMobile,
        isEmbedMeeting,
      }}
    >
      {loading ? (
        <div
          style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Spin />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
