import { Layout } from 'antd';
import { FC } from 'react';
import cls from 'classnames';
import s from './style.module.less';

const { Content } = Layout;

const AppLayout: FC = ({ children }) => {
  return (
    <>
      <Layout className={cls(s.appLayout, 'layout')}>
        <Content className={cls(s.contentCon, 'layout--content')}>{children}</Content>
      </Layout>
    </>
  );
};

export default AppLayout;
