import { TAB_ITEM, FELO_DOC_POSITION_PAYLOAD, MARKER_ITEM } from '@/types';
import { WITH_ID_OBJECT } from '@/utils';
import { createContext } from 'react';

export interface ResourceType {
  action: string;
  data?: any;
}
interface TabContextInterface {
  fetchTabs: (cb?: Function) => void;
  activeTabId: number;
  setActiveTabId: (id: number) => void;
  items: WITH_ID_OBJECT[];
  setItems: (items: WITH_ID_OBJECT[]) => void;
  orderItems: TAB_ITEM[];
  cacheTabs: number[];
  setOrderItems: (items: TAB_ITEM[]) => void;
  tabsLoading: boolean;
  setTabsLoading: (loading: boolean) => void;
  setResourceType: (resource?: ResourceType) => void;
  resourceType?: ResourceType;
  docAddLoading: string;
  setDocAddLoading: (data: string) => void;
  newTab: (options: Tab.NewTab) => Promise<void>;
  mqttPubUpdate: () => void;
  mqttPubUpdateActiveTab?: () => void;
  deleteTab: (tabId: number, deleteAllOther?: boolean) => void;
  updateOrder?: () => void;
  pubUpdateActiveId: (arg: any) => void;
  pubUpdateTabsort: (arg: any) => void;
  mqttPubUpdatePDF: (arg: any) => void;
  mqttPubUpdateYT: (arg: any) => void;
  refreshTabId: string;
  setRefreshTabId: (arg: any) => void;
  mqttSyncStatus: (arg: boolean) => void;
  addedFiles: any[];
  setAddedFiles: (arg: any[]) => void;
  syncActiveTab: () => void;
  mqttPubUpdatePptIndex: (index: number, tabId: number) => void;
  mqttPubFeloDocPosition: (payload: FELO_DOC_POSITION_PAYLOAD) => void;
  showSnapshoot: boolean;
  setShowSnapshoot: (state: boolean) => void;
  mqttPubWhiteboard: (x: number, y: number, zoom: number, width: number) => void;
  youtubePlayers: any;
  setYoutubePlayers: (arg: any) => void;
  mqttPubGetYTProgress: (arg: any) => void;
  mqttPubMarker: (tabId: number, x: number, y: number, zoom: number, width: number, height: number) => void;
  mqttPubMarkerVisible: (state: MARKER_ITEM) => void;
  markerVisible: MARKER_ITEM;
  setMarkerVisible: (state: MARKER_ITEM) => void;
}

export const TabContext = createContext<TabContextInterface>({
  fetchTabs: () => {},
  activeTabId: 0,
  setActiveTabId: (id) => {},
  items: [],
  setItems: (items: WITH_ID_OBJECT[]) => {},
  orderItems: [],
  setOrderItems: (items: WITH_ID_OBJECT[]) => {},
  tabsLoading: false,
  setTabsLoading: (loading: boolean) => {},
  setResourceType: (resource?: ResourceType) => {},
  docAddLoading: '',
  setDocAddLoading: (data: string) => {},
  newTab: (options: Tab.NewTab) => {
    return Promise.resolve();
  },
  mqttPubUpdate: () => {},
  mqttPubUpdateActiveTab: () => {},
  mqttPubUpdatePDF: () => {},
  mqttPubUpdateYT: () => {},
  deleteTab: (tabId: number, deleteAllOther = false) => {},
  updateOrder: () => {},
  pubUpdateActiveId: () => {},
  pubUpdateTabsort: () => {},
  refreshTabId: '',
  setRefreshTabId: () => {},
  mqttSyncStatus: () => {},
  addedFiles: [],
  cacheTabs: [],
  setAddedFiles: () => {},
  syncActiveTab: () => {},
  mqttPubUpdatePptIndex: () => {},
  mqttPubFeloDocPosition: () => {},
  showSnapshoot: false,
  setShowSnapshoot: (state: boolean) => {},
  mqttPubWhiteboard: (x: number, y: number, zoom: number, width: number) => {},
  youtubePlayers: false,
  setYoutubePlayers: () => {},
  mqttPubGetYTProgress: () => {},
  mqttPubMarker: (tabId: number, x: number, y: number, zoom: number, width: number, height: number) => {},
  mqttPubMarkerVisible: (state: MARKER_ITEM) => {},
  markerVisible: {},
  setMarkerVisible: (state: MARKER_ITEM) => {},
});
