export const TOKEN_KEY = 'token';
export const TEAM_ID = 'teamId';
export const OPEN_ID = 'openId';
export const TOKEN_TIME_KEY = 'expired_time';

export const APP_ID = 'yd2A7DBDBF85864335BDA352584E9D4378';

// 模板文档地址
export const DOC_TEMPLATE_URL = (teamId: string | null) => {
  if (process.env.IS_PROD_ENV) {
    return teamId && teamId !== 'anonymous'
      ? `https://doc.felo.me/doc/${teamId}/template`
      : 'https://doc.felo.me/doc/template';
  }

  return teamId && teamId !== 'anonymous'
    ? `https://doc-dev.felo.me/doc/${teamId}/template`
    : 'https://doc-dev.felo.me/doc/template';
};

// mock meet id
export const MOCK_MEET_ID = '';
export const MOCK_TOPIC = 'topic-meet';
export const MOCK_TOPIC_URL = `/felo/app/${APP_ID}/${MOCK_TOPIC}`;
