import { TEAM_ID, TOKEN_KEY, TOKEN_TIME_KEY } from '@/lib/constants';
import { sdkLogin } from '@/model/AuthProvider/Provider';
import axios, { AxiosError } from 'axios';

export const getToken = () => {
  const token = sessionStorage.getItem(TOKEN_KEY);
  const expires_in = sessionStorage.getItem(TOKEN_TIME_KEY) || '';
  if (expires_in) {
    // 获取token过期了的时候就刷新
    const nowTime = new Date().getTime();
    if (parseInt(expires_in, 10) - nowTime < 300000) {
      sdkLogin();
    }
  }

  return token;
};

const instance = axios.create({
  baseURL: '',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    const isAWS = config.url?.includes('amazonaws.com');

    if (token && config.headers && !isAWS) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['x-drive-type'] = 'doc';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      if (error.config.headers && error.config.headers.Authorization) {
        console.log('Token expired');
        // token 过期自动重新登录并重试请求
        const teamId = localStorage.getItem(TEAM_ID);
        const newToken = await sdkLogin(teamId ? [teamId] : []);

        error.config.headers.Authorization = `Bearer ${newToken}`;
        return await Promise.resolve(axios.request(error.config));
      }
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  },
);

export default instance;
