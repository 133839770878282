import { fetchMQTT } from '@/services';
import { message } from 'antd';
import type { MqttClient } from 'mqtt';
import * as mqtt from 'mqtt/dist/mqtt.min';
import { useRouter } from 'next/router';
import { AuthContext } from '@/model/AuthProvider/Context';
import PubSub from 'pubsub-js';
import { PropsWithChildren, useEffect, useState, useContext, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { MQTTContext } from './Context';
import { MARKER_ITEM } from '@/types';

export function MQTTProvider({ children }: PropsWithChildren<unknown>) {
  const [baseConfig, setBaseConfig] = useState<MQTT.CONFIG | null>(null); // MQTT 基础配置
  const [connectionStatus, setConnectionStatus] = useState(false); // 连接状态
  const [client, setClient] = useState<MqttClient | null>(null); // client 实例
  const [syncStatus, setSyncStatus] = useState(false); // 是否跟随主持人操作同步状态
  const [pptSyncStatus, setPptSyncStatus] = useState<MQTT.PptSyncStatus>(); // PPT 演示位置
  const [whiteboardSyncStatus, setWhiteboardSyncStatus] = useState<MQTT.WhiteboardSyncStatus>(); // 白板位置
  const [markerSyncStatus, setMarkerSyncStatus] = useState<MQTT.MarkerSyncStatus>(); // 标记位置
  const [syncMarkerVisible, setSyncMarkerVisible] = useState<MARKER_ITEM>({}); // 标识是否开启
  const [pdfSyncStatus, setPdfSyncStatus] = useState<MQTT.PdfSyncStatus>(); // PDF位置
  const intl = useIntl();
  const router = useRouter();
  const meetId = (router.query.meetId || '') as string;
  const { state } = useContext(AuthContext);

  useEffect(() => {
    setSyncStatus(!!state.role_list.synchro_user);
  }, [state.role_list.synchro_user]);

  const initMqtt = useCallback(() => {
    fetchMQTT({ meetId })
      .then((res) => {
        const mqttConfig = res?.data?.data?.data ?? null;
        if (mqttConfig) {
          // 连接选项
          const options: mqtt.IClientOptions = {
            keepalive: 5,
            // clean: true, // true: 清除会话, false: 保留会话
            // connectTimeout: 4000, // 超时时间
            // 认证信息
            clientId: mqttConfig.client_id,
            username: mqttConfig.username,
            password: mqttConfig.token,

            // 遗嘱消息
            will: {
              topic: mqttConfig.topic,
              payload: 'will data',
              qos: 1,
              retain: true,
            },
          };

          const mqttClient = mqtt.connect(mqttConfig.ws_url, options);
          mqttClient.on('connect', () => {
            console.log('Panel connected!');
            setConnectionStatus(true);
            message.destroy();
          });
          mqttClient.on('error', (err: { code: number }) => {
            console.log('Panel mqtt error', err);
            if (err.code === 4) {
              mqttClient?.end(true, {}, () => {
                initMqtt();
                console.log('Panel The client has ended for username or password error');
              });
            } else {
              console.log('Panel Mqtt error with other status: ', err);
            }
          });
          mqttClient.on('reconnect', () => {
            console.log('Panel mqtt reconnect');
          });
          setBaseConfig(mqttConfig);
          setClient(mqttClient);
        }
      })
      .catch((err) => {
        console.log('Panel Fetch mqtt config failed: ', err.message);
      });
  }, [meetId]);

  useEffect(() => {
    if (meetId) {
      initMqtt();
    }
  }, [meetId, initMqtt]);

  useEffect(() => {
    if (client && connectionStatus && baseConfig) {
      // 取消订阅 topic
      client.unsubscribe(baseConfig.topic, { qos: 1 }, function (error, granted) {
        if (error) {
          console.log(error);
        } else {
          // 订阅 topic
          client.subscribe(baseConfig.topic, { qos: 1 }, function (error, granted) {
            if (error) {
              console.log(error);
            } else {
              console.log(`${baseConfig.topic} was subscribed`);
            }
          });
        }
      });
    }

    return () => {
      if (client && connectionStatus && baseConfig) {
        client.unsubscribe(baseConfig.topic, { qos: 1 }, () => {});
      }
    };
  }, [client, baseConfig, connectionStatus]);

  return (
    <MQTTContext.Provider
      value={{
        connectionStatus,
        client,
        baseConfig,
        syncStatus,
        setSyncStatus,
        pptSyncStatus,
        setPptSyncStatus,
        whiteboardSyncStatus,
        setWhiteboardSyncStatus,
        markerSyncStatus,
        setMarkerSyncStatus,
        syncMarkerVisible,
        setSyncMarkerVisible,
        pdfSyncStatus,
        setPdfSyncStatus,
      }}
    >
      {children}
    </MQTTContext.Provider>
  );
}
