import { MARKER_ITEM } from '@/types';
import { createContext, Dispatch } from 'react';

export interface AuthState {
  meet_id: string; //会议ID
  open_with: 1 | 2; //打开方式  1会议内 2单独打开
  open_id: string; //用户openID
  //角色属性 1会议创建者 2会议主持人 3参会人/组织内成员 4参会人/组织外成员 5参会人/匿名用户 6独立用户组织内成员 7独立用户组织外成员 8独立匿名用户
  role_attribute: 1 | 2 | 3 | 4 | 5 | 6;
  //角色属性对应名
  role_attribute_name: string;
  //权限数据
  role_list: {
    read: 1 | 2; //读 1允许 2不允许
    edit: 1 | 2; //编辑 1允许 2不允许
    del: 1 | 2 | 3 | 4; //删除 1允许 2不允许 3只允许自己 4没功能
    upload: 1 | 2 | 4; //上传 1允许 2不允许 4没功能
    synchro: 1 | 2; // 1 可以同步、2 不可以同步
    synchro_user: string; // 同步者 id
    synchro_user_name: string; // 同步者用户名
    marker: 1 | 2; // 1 开启标记、2 关闭标记
    marker_list: MARKER_ITEM | null;
  };
  corp_id: string; //当前会议组织ID
  attendee_file_sta: 1 | 2; //参会人文件状态 1可阅读 2可编辑阅读
  attendee_upload_sta: 1 | 2; //参会人上传状态 1允许上传 2不允许上传
  meet_plan_max_tab_count: number;
}

export const initialState: AuthState = {
  meet_id: '',
  open_with: 2,
  open_id: '',
  role_attribute: 1,
  role_attribute_name: '',
  role_list: {
    read: 1,
    edit: 2,
    del: 2,
    upload: 2,
    synchro: 1,
    synchro_user: '',
    synchro_user_name: '',
    marker: 2,
    marker_list: null,
  },
  corp_id: '',
  attendee_file_sta: 2,
  attendee_upload_sta: 1,
  meet_plan_max_tab_count: 10,
};

export enum ActionKind {
  UPDATE_STATE,
}

export type Action = {
  type: ActionKind.UPDATE_STATE;
  payload: AuthState;
};

export const AuthContext = createContext<{
  meetId: string;
  teamId: string;
  state: AuthState;
  dispatch: Dispatch<Action>;
  canEdit: boolean;
  canDelete: boolean;
  canUpload: boolean;
  setMeetId: Function;
  updatePower: Function;
  userLogin: Function;
  isDemoHost: boolean;
  isDemoMode: boolean;
  isMobile: boolean;
  isEmbedMeeting: boolean;
}>({
  meetId: '',
  teamId: '',
  state: initialState,
  dispatch: () => null,
  canEdit: true,
  canDelete: true,
  canUpload: true,
  setMeetId: () => {},
  updatePower: () => {},
  userLogin: () => {},
  isDemoHost: true,
  isDemoMode: false,
  isMobile: false,
  isEmbedMeeting: false,
});
