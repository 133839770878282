export const imgPreviewSuffix = ['bmp', 'png', 'gif', 'jpg', 'jpeg', 'svg', 'webp'];
export const msDocPreviewSuffix = ['ppt', 'pptx', 'pps', 'doc', 'docx', 'xlsx', 'xls'];

export const wordPreviewSuffix = ['doc', 'docx'];
export const pptPreviewSuffix = ['ppt', 'pptx', 'pps'];
export const excelPreviewSuffix = ['xlsx', 'xls'];

export const audioPreviewSuffix = ['mp3', 'ogg'];
export const videoPreviewSuffix = ['mp4', 'mkv', 'webm'];
export const pdfPreviewSuffix = ['pdf'];
export const editSuffix = ['md', 'txt'];

export const zipSuffix = ['zip', 'gz', 'tar', 'rar', '7z'];

export const codePreviewSuffix = {
  json: 'json',
  php: 'php',
  py: 'python',
  bat: 'bat',
  cpp: 'cpp',
  c: 'cpp',
  h: 'cpp',
  cs: 'csharp',
  css: 'css',
  dockerfile: 'dockerfile',
  go: 'go',
  html: 'html',
  ini: 'ini',
  java: 'java',
  js: 'javascript',
  jsx: 'javascript',
  less: 'less',
  lua: 'lua',
  sh: 'shell',
  sql: 'sql',
  xml: 'xml',
  yaml: 'yaml',
};

export const mediaType = {
  audio: ['mp3', 'flac', 'ape', 'wav', 'acc', 'ogg'],
  video: ['mp4', 'flv', 'avi', 'wmv', 'mkv', 'rm', 'rmvb', 'mov', 'ogv'],
  image: ['bmp', 'iff', 'png', 'gif', 'jpg', 'jpeg', 'psd', 'svg', 'webp'],
  pdf: ['pdf'],
  word: ['doc', 'docx'],
  ppt: ['ppt', 'pptx'],
  excel: ['xls', 'xlsx', 'csv'],
  text: ['txt', 'md', 'html'],
  torrent: ['torrent'],
  zip: ['zip', 'gz', 'tar', 'rar', '7z'],
  excute: ['exe'],
  android: ['apk'],
  php: ['php'],
  go: ['go'],
  python: ['py'],
  cpp: ['cpp'],
  c: ['c'],
  js: ['js', 'jsx'],
};

export const isPreviewable = (name) => {
  if (!name) {
    return false;
  }

  const suffix = name.split('.').pop().toLowerCase();
  if (imgPreviewSuffix.indexOf(suffix) !== -1) {
    return 'img';
  } else if (wordPreviewSuffix.indexOf(suffix) !== -1) {
    return 'word';
  } else if (pptPreviewSuffix.indexOf(suffix) !== -1) {
    return 'ppt';
  } else if (excelPreviewSuffix.indexOf(suffix) !== -1) {
    return 'excel';
  } else if (msDocPreviewSuffix.indexOf(suffix) !== -1) {
    return 'msDoc';
  } else if (audioPreviewSuffix.indexOf(suffix) !== -1) {
    return 'audio';
  } else if (videoPreviewSuffix.indexOf(suffix) !== -1) {
    return 'video';
  } else if (editSuffix.indexOf(suffix) !== -1) {
    return 'edit';
  } else if (pdfPreviewSuffix.indexOf(suffix) !== -1) {
    return 'pdf';
  } else if (zipSuffix.indexOf(suffix) !== -1) {
    return 'zip';
  } else if (Object.keys(codePreviewSuffix).indexOf(suffix) !== -1) {
    return 'code';
  }
  return false;
};

