import English from '@/locales/enUS.json';
import Japanese from '@/locales/jaJP.json';
import SimplifiedChinese from '@/locales/zhCN.json';
import TraditionalChinese from '@/locales/zhTW.json';
import { useRouter } from 'next/router';
import { createContext, useMemo, FC, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { isServer } from '../utils';

export const localesMap = {
  ja: 'ja-JP',
  en: 'en-US',
  'zh-Hant': 'zh-TW',
  'zh-hans': 'zh-TW',
  zh_CN: 'zh-TW',
  zh: 'zh-TW',
};

export const IntlContext = createContext<{
  currentLocale: string;
  setCurrentLocale: Function;
}>({
  currentLocale: 'en-US',
  setCurrentLocale: () => {},
});

const AppIntl: FC = ({ children }) => {
  const { locale = 'en-US', defaultLocale, locales = [] } = useRouter();
  const [currentLocale, setCurrentLocale] = useState(locale);

  const messages = useMemo(() => {
    switch (currentLocale) {
      case 'en-US':
        return English;
      // case 'zh-CN':
      //   return SimplifiedChinese;
      case 'zh-TW':
        return TraditionalChinese;
      case 'ja-JP':
        return Japanese;
      default:
        return English;
    }
  }, [currentLocale]);

  useEffect(() => {
    if (!isServer) {
      let customLocale = navigator.language;
      if (customLocale) {
        let localeData = customLocale.split('-');
        if (localeData.length > 1) {
          customLocale = `${localeData[0]}-${localeData[1].toUpperCase()}`;
          if (!locales.includes(customLocale)) {
            customLocale = localeData[0];
            localeData = [customLocale];
          }
        }
        if (localeData.length === 1) {
          // 没有zh jp 这样没有横线，试着查找相关语言
          customLocale = locales.find((l) => l.toLowerCase().startsWith(`${customLocale?.toLowerCase()}-`)) || '';
          // 前面没找到就找后面
          if (!customLocale) {
            customLocale = locales.find((l) => l.toLowerCase().endsWith(`-${customLocale?.toLowerCase()}`)) || '';
          }
        }
      }

      setCurrentLocale(customLocale);
    }
  }, []);

  return (
    <IntlContext.Provider value={{ currentLocale, setCurrentLocale }}>
      <IntlProvider
        locale={currentLocale}
        messages={messages}
        onError={() => {
          console.error('IntlProvider error');
        }}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

export default AppIntl;
