import service from './http';
import { DOC_PARAMS, MARKER_ITEM } from '@/types';
type MeetObject = {
  meetId: string;
};

// 创建|查询 协作面板
export const fetchPanelList = ({ meetId }: MeetObject) => {
  return service.request({
    method: 'post',
    url: `/api/v4/panel/main`,
    data: {
      meet_id: meetId,
      is_panel_details_title: '2', // 不查URL中title和图标, 影响接口速度
    },
  });
};

// 单独查询协作面板资源标题，图标数据
export const fetchTabTitle = ({ meetId }: MeetObject) => {
  return service.request({
    method: 'post',
    url: `/api/v4/panel/detail/otherInfo`,
    data: {
      meet_id: meetId,
    },
  });
};

// 添加窗口
export const createPanel = ({
  type,
  payload,
  meetId,
  docId,
  pptId,
}: { type: number; payload: string; docId?: number; pptId?: string } & MeetObject) => {
  return service.request<API.NewTabResult>({
    method: 'post',
    url: `/api/v4/panel/detail`,
    data: {
      meet_id: meetId,
      type, //1.doc文件 2.本地文件 3.url地址 6.从模板添加 7.白板
      run_uuid: payload, //相关ID  比如type是doc就填doc的UUID  type是url就填url地址
      doc_id: docId || 0, // 新增的类型是 6 时需要模板 id 参数
      ppt_id: pptId || '', // 给后端去 ppt2image 下载转换后的 zip 文件凭据
    },
  });
};

// 批量添加窗口
export const createPanelBatch = (data: ({ type: number; payload: string; docId?: number } & MeetObject)[]) => {
  return service.request<API.NewTabResult>({
    method: 'post',
    url: `/api/v4/panel/detail/list`,
    data,
    // : {
    //   meet_id: meetId,
    //   type, //1.doc文件 2.本地文件 3.url地址 6.从模板添加 7.白板
    //   run_uuid: payload, //相关ID  比如type是doc就填doc的UUID  type是url就填url地址
    //   doc_id: docId || 0, // 新增的类型是 6 时需要模板 id 参数
    // },
  });
};

// 删除窗口
export const deletePanel = (detailId: number) => {
  return service.request({
    method: 'delete',
    url: `/api/v4/panel/detail/${detailId}`,
  });
};

// 批量删除窗口
export const deleteBatchPanel = (list: (string | number)[], { meetId }: MeetObject) => {
  return service.request({
    method: 'delete',
    url: `/api/v4/panel/batch_detail`,
    data: {
      detail_list_id: list, //窗口ID列表
      meet_id: meetId, //会议ID
    },
  });
};

// 查询文档列表
export const fetchDocList = ({ page, page_size, type, keyword = '' }: DOC_PARAMS) => {
  return service.request({
    method: 'get',
    url: `/api/v4/search/keywords/${keyword}`,
    params: {
      page,
      page_size,
      type,
    },
  });
};

// PPT 文件上传到 ppt2image
export const uploadFile2Image = ({ pptId, file }: { pptId: string; file: File }) => {
  const formData = new FormData();
  formData.append('file', file);

  return service.request({
    method: 'post',
    timeout: 60000,
    // url: `http://localhost:3000/file/upload?pptId=${pptId}&isDev=${process.env.IS_PROD_ENV ? 0 : 1}`,
    url: `/api-doc-snapshot/ppt2image_vbs/api/file/upload?pptId=${pptId}&isDev=${process.env.IS_PROD_ENV ? 0 : 1}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

// 文件上传
export const UploadStep1 = (filename: string) => {
  return service.request({
    method: 'post',
    url: `/api/v4/panel/detail/fileUploadHeader`,
    data: {
      file_name: filename,
    },
  });
};
export const UploadStep2 = (data: BinaryData, url: string) => {
  return service.request({
    method: 'put',
    url,
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};
export const UploadStep3 = (oss_url: string) => {
  return service.request({
    method: 'put',
    url: `/api/v4/panel/detail/fileUpload/update`,
    data: {
      oss_url,
      sta: 1,
    },
  });
};

// 下载文件
export const downloadFile = (url: string) => {
  return service.request({
    method: 'get',
    url,
    responseType: 'blob',
  });
};

// 获取 MQTT 链接信息
export const fetchMQTT = ({ meetId }: MeetObject) => {
  return service.request({
    method: 'get',
    url: `/api/v4/panel/ws/topicCreate/${meetId}`,
  });
};

// 获取最近使用模板
export const fetchRecentTemplate = (lang?: string) => {
  return service.request({
    method: 'get',
    url: `/api-template/api/templates`,
    params: {
      size: 3,
      page: 1,
      recommend: true,
      lang: lang || 'zh-Hans',
      order_by: '-oid',
    },
  });
};

// 查询当前选中窗口
export const fetchActiveTab = ({ meetId }: MeetObject) => {
  return service.request({
    method: 'get',
    url: `/api/v4/panel/detail/activeTabId/${meetId}`,
  });
};

// 修改当前选中窗口
export const updateActiveTab = (id: number, { meetId }: MeetObject) => {
  return service.request({
    method: 'post',
    url: `/api/v4/panel/detail/activeTabId`,
    data: {
      meet_id: meetId,
      active_tab_id: id,
    },
  });
};

// 修改窗口排序
export const updateTabSort = (ids: number[], { meetId }: MeetObject) => {
  return service.request({
    method: 'put',
    url: `/api/v4/panel/detail/sort`,
    data: {
      meet_id: meetId,
      run_id: ids,
    },
  });
};

// 文件转存到云盘
export const saveToDrive = (id: number, { meetId }: MeetObject) => {
  return service.request({
    method: 'post',
    url: `/api/v4/panel/detail/file/saveDrive`,
    data: {
      meet_id: meetId,
      id,
    },
  });
};

// 查询链接嵌入 url
export const fetchEmbedLink = (url: string) => {
  return service.request({
    method: 'get',
    url,
  });
};

// 设置新创建文档分享状态
export const setDocShareType = (id: string) => {
  return service.request({
    method: 'post',
    url: `/api/v4/share_type`,
    data: {
      run_type: 2, //固定
      fid_ps: id, //uuid
      share_type: 0, //固定
    },
  });
};

/**
 * 获取 SVG 图片
 * @param url
 * @returns
 */
export const getSvg = (url: string) => {
  return service.request<string>({
    method: 'get',
    url,
  });
};

/**
 * 修改演讲者
 * 1设置 2取消演讲人（取消后演讲人将为空）
 * marker 是否开启了标记功能
 */
export const updateSpeaker = (status: 1 | 2, { meetId }: MeetObject, marker_list?: MARKER_ITEM, marker?: 1 | 2) => {
  return service.request({
    method: 'put',
    url: `/api/v4/panel/speaker`,
    data: {
      meet_id: meetId,
      sta: status,
      marker_list: marker_list || {},
      marker: marker || 2,
    },
  });
};

// 创建新的空白文档
export const newEmptyFeloDoc = () => {
  return service.request({
    method: 'post',
    url: `/api-doc/docs`,
    data: {
      comment_mark: [],
      content: '<p><br /></p>',
      description: '',
      html: '<p><br /></p>',
      title: '',
      version: '',
    },
  });
};

// 查询是否是会议中状态
export const fetchMeetStatus = (meetId: string) => {
  return service.request({
    method: 'post',
    url: `/api-minutes/api/v5/felo-minutes/anonymous/meet/meetSimpleQuery`,
    data: {
      meetId,
    },
  });
};

// 查询匿名用户名称
export const fetchAnonymousNickName = (meetId: string) => {
  return service.request({
    method: 'post',
    url: `/api-minutes/api/v5/felo-minutes/public/meet/joinerNickname`,
    data: {
      meetId,
    },
  });
};

// 获取面板 tab 截图 & 检测 URL 地址是否可以被 iframe 嵌入
export const fetchScreenshot = (url: string) => {
  return service.request<API.ScreenshotResult>({
    method: 'get',
    // url: `http://localhost:3444/screenshot`, // Dev Only
    url: `/api-doc-snapshot/ppt2image/api/screenshot`,
    params: {
      url,
    },
  });
};

// Tab 更新快照
export const updatePanelSnapshot = ({
  detailId,
  meetId,
  imgBase64,
  previewshotSrc,
  src,
  isEmbeddable,
}: {
  detailId: number;
  meetId: string;
  imgBase64?: string;
  previewshotSrc?: string;
  src?: string;
  isEmbeddable?: number;
}) => {
  return service.request({
    method: 'put',
    url: `/api/v4/panel/detail/snapshot`,
    data: {
      meet_id: meetId,
      detail_id: detailId,
      img_base64: imgBase64,
      preview_img_base_64: previewshotSrc,
      src,
      preview_src: '',
      is_embeddable: isEmbeddable,
    },
  });
};
